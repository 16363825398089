<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Doanh Nghiệp vận tải </a></li>
    </ol>
    <div class="container-fluid">
      <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <router-link to="/admin/business/add" class="btn btn-sm btn-primary">
                <i class="fas fa-plus"></i>
                Thêm mới Doanh Nghiệp vận tải
              </router-link>

            </div>


              

          </div>
           <div class="col-sm-1" v-if="is_loading">
          <MyLoading></MyLoading>
            </div>
           <div class="col-sm-1" v-else>
              <div class="form-group">

                <xlsx-workbook>
                  <xlsx-sheet
                    
                    v-for="sheet in sheets"
                    :key="sheet.name"
                    :sheet-name="sheet.name"
                    :collection="sheet.data"
                  />
                  <xlsx-download :filename="sheetName">
                    <button  class="btn btn-sm btn-success">
                       <i class="fas fa-file-export"></i>
                  Export 
                   
                    </button>
                  </xlsx-download>
                  
                </xlsx-workbook>
                  


                 
              </div>
            </div>

              <div class="col-sm-1">
              <div class="form-group">

                
                  


                 <router-link to="/admin/business/import" class="btn btn-sm btn-success ">
                   <i class="fas fa-file-import"></i>
                  Import  
                </router-link>
              </div>


              


            </div>


             <button style="display: ;"  class="btn btn-sm btn-danger ml-5" @click="RunDelete()">
                <i class="fas fa-remove"></i>
                    Delete All Data
              </button>
      </div>

      <div>
        <form method="get"  @submit.prevent="onSearch"  id="my_search">
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
                v-model="formSearch.name"
                autocomplete="off"
                placeholder="Nhập tên..."

                
              />

              <button class="btn btn-sm btn-warning mt-3" type="submit">
                <i class="fa fa-search-plus"></i> TÌM KIẾM
              </button>
            </div>
             
          </div>
        </form>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <MyLoadTheme color="#e6f3fd" highlight="#eef6fd">
                <MyLoading width="40px" height="40"></MyLoading>
              </MyLoadTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Doanh Nghiệp vận tải : ( {{ page_size }} )
            </div>
            <div class="card-block">
              <MyLoadTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                       <th>Mã </th>
                      <th>Doanh nghiệp  </th>
                      <th> LOGO </th>
                      <th> Quầy vé  </th>
                      <th> Địa chỉ  </th>
                      <th> Email  </th>
                      <th> Số điện thoại  </th>
                      <th> Fax </th>
                      <th> Website </th>
                      <th>Tuỳ chọn</th>

                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 20" :key="k">
                      <td v-for="kk in 10" :key="kk">
                        <MyLoading height="50px"></MyLoading>
                      </td>
                      
                    </tr>
                  </tbody>
                  <tbody v-else>




                    <tr
                      v-for="(value, key) in paginationslice(mydata, 10, page)"
                      :Key="key"
                    >
                       <td>
                      
                          {{value.code}}
                          
                      </td>
                      
                      <td >
                       {{ value.name  }} 
                      </td>
                      
                       <td >
                       <img
                            :src="my_url + '' + value.picture"
                            :title="value.picture"
                            width="100px"
                          /> 
                      </td>
                       <td>
                       {{ value.ticketcounter  }} 
                      </td>
                        <td>
                       {{ value.address  }} 
                      </td>
                       <td>
                       {{ value.email  }} 
                      </td>
                       <td>
                       {{ value.phone  }} 
                      </td>
                       <td>
                       {{ value.fax  }} 
                      </td>
                       <td>
                       {{ value.website  }} 
                      </td>
                      <td>
                        <router-link
                          class="btn btn-sm btn-success"
                          v-bind:to="'/admin/business/edit/' + value.id"
                        >
                          <i class="fa fa-edit"></i>
                          Sửa
                        </router-link>
                        <div
                          class="btn btn-sm btn-danger click_remove"
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-remove"></i> Xoá
                        </div>

                      
                      </td>

                      
                    </tr>
                  </tbody>
                </table>

               <nav v-if="mydata != null && mydata.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </MyLoadTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>

import VuePagination from "vuejs-paginate";
import axios from "axios";


import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      main:null,
      location:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:""
      },
      language: window.my_header.language,
      role:2,

      sheetName:"Doanh_Nghiep_"+(new Date()).getDate().toString()+(new Date()).getFullYear().toString()+".xlsx",
      sheets: [{ name: "BEN XE MIEN DONG", data: [  {"BUITIN":"TEST"} ] }],
      
    };
  },
  components: {
   
    VuePagination,
    XlsxWorkbook, XlsxSheet, XlsxDownload
  },
  created(){  
      let isLogin= this.$session.get("isLogin");
      this.isLogin=isLogin.permistions.split(",");

      let Customer= this.$session.get("Customer");
      this.role=Customer.isRole;
  },
  mounted() {

   

  

   let p2=  axios
      .get(window.my_api + "api/business/get-businesss", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata  = this.main= res.data.data;
          this.page_size=this.mydata.length;
            
          if(this.$route.query.name !=undefined){
              this.formSearch.name=this.$route.query.name;
              this.mydata=this.main.filter(n => n.name.toUpperCase().search(this.$route.query.name.toUpperCase()) > -1 );
          }
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p2])
             .then( ()=>{


                 this.sheets[0]['data']=[];
                  let DataSheets=[];

                  for (var i = this.mydata.length - 1; i >= 0; i--) {
                    
                    DataSheets.push({
                           "Mã ":this.mydata[i].code,
                          'Doanh nghiệp': this.mydata[i].name,
                          "Quầy vé":this.mydata[i].ticketcounter,
                          'Địa chỉ': this.mydata[i].address == undefined ? "" :this.mydata[i].address  ,
                          'Email': this.mydata[i].email == undefined ? "" :this.mydata[i].email,
                          'Số điện thoại': this.mydata[i].phone == undefined ? "" :this.mydata[i].phone,
                          'Fax':  this.mydata[i].fax == undefined ? "" :this.mydata[i].fax ,
                          'Website': this.mydata[i].website == undefined ? "" :this.mydata[i].website ,

                    });

                  } 


                  this.sheets[0]['data']=DataSheets;

                this.is_loading = false;
             });
  },
  methods: {
    onSearch:function(){

      
        

          this.$router.push({query:{'name':this.formSearch.name}});

          this.mydata=this.main.filter(n => n.name.toUpperCase().search(this.formSearch.name.toUpperCase()) > -1);
    },
     changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },


    
    removeElement(id) {
        if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/business/remove-business", {
                data: { businessIds: [id] },
                headers: window.my_header
              })
              
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");
                  this.mydata = this.main.filter((el) => el.id !== this.id_load);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
      
    },

     RunDelete(){
          if(confirm("Bạn muốn xoá tất cả dữ liệu tuyến xe Bus?")){
                let id_data=[];
                for (var i = this.main.length - 1; i >= 0; i--) {
                    id_data.push(this.main[i].id) ;
                }

                  axios
                      .delete(window.my_api + "api/business/remove-business", {
                        data: { businessIds: id_data },
                        headers: window.my_header
                      })
                          
                      .then((res) => {
                        if (res.status == 200) {
                          alert("Xoá thành công.");

                         
                        } else {
                          alert(
                            "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                          );
                        }
                       
                      

                     });
         }
  

     }
   }
};
</script>








